@media screen and (max-width: 1024px) {
  #header .header-content {
    width: 90%;
    padding: 10px 0;
  }

  #header.fixed a {
    color: #353535;
  }

  .nav-toggle {
    display: block;
  }

  .navigation {
    position: fixed;
    background-color: #313131;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99999;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: opacity 0.5s, visibility 0s 0.5s;
    transition: opacity 0.5s, visibility 0s 0.5s;
  }

  .navigation .primary-nav {
    position: relative;
    top: 45%;
    -moz-transform: translateY(-45%);
    -ms-transform: translateY(-45%);
    -webkit-transform: translateY(-45%);
    transform: translateY(-45%);
  }

  .navigation li {
    display: block;
    margin-bottom: 1px;
  }

  .navigation a {
    display: block;
    font-size: 18px;
    margin: 0;
    text-align: center;
    padding: 17px 0;
    color: #fff;
  }

  .person {
    margin: 0 auto 50px;
  }

  .person-content {
    text-align: center;
  }

  .person .social-icons li {
    display: inline-block;
    float: none;
    margin-bottom: 5px;
  }

  .testimonials blockquote {
    padding: 20px 5% 0;
  }

  .testimonials p {
    font-size: 12px;
  }

  .flex-control-nav {
    bottom: 20px;
  }
}

@media screen and (max-width: 768px) {
  #header.fixed a {
    color: #fff;
  }

  #header.fixed a.logo {
    color: #000;
  }

  .banner-text {
    padding-top: 30%;
  }

  .banner-text h1 {
    font-size: 42px;
  }

  .banner-text p {
    font-size: 18px;
  }

  .flexslider {
    padding-bottom: 80px;
  }

  .flex-control-nav {
    width: 100%;
  }

  .footer .footer-col {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 640px) {
  #first-slider .carousel .item {
    min-height: 468px;
    height: 100%;
    width: 100%;
  }

  .banner-text {
    padding-top: 57%;
  }

  .testimonials p {
    font-size: 12px;
  }

  #first-slider .slide2, #first-slider .slide1, #first-slider .slide3, #first-slider .slide4, #first-slider .slide5 {
    background-position-x: -402px;
  }
}

@media screen and (max-width: 480px) {
  .banner-text {
    padding-top: 45%;
  }

  .banner-text h1 {
    font-size: 32px;
  }

  .testimonials p {
    font-size: 12px;
  }
}

@media screen and (max-width: 320px) {
  .banner-text {
    padding-top: 55%;
  }

  .testimonials blockquote {
    padding: 20px 5% 0;
  }

  .testimonials p {
    font-size: 12px;
  }

}

